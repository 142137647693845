import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import seoContent from "../helpers/seoContent"

const { siteDescriptions } = seoContent

const Blog = () => (
  <Layout>
    <SEO title="Blog" description={siteDescriptions.default} />
    <h1>Blog</h1>
  </Layout>
)

export default Blog
